@mixin for-mobile-layout {
  @media (max-width: calc($mobile-breakpoint + 40px)) {
    @content;
  }
}

//dont use
@mixin for-narrow-layout {
  @media (min-width: calc($mobile-breakpoint + 40px)) {
    @content;
  }
}

//dont use
@mixin for-normal-layout {
  @media (min-width: calc($narrow-breakpoint + 40px)) {
    @content;
  }
}

@mixin for-tablet-layout {
  @media (max-width: calc($tablet-breakpoint + 40px)) {
    @content;
  }
}

@mixin for-wide-layout {
  @media (max-width: calc($normal-breakpoint + 40px)) {
    @content;
  }
}
