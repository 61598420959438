.nav-container {
  position: relative;
  nav {
    color: $clr1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
    display: flex;
    align-items: center;
    background-color: $clr2;
    height: auto;
    justify-content: space-between;
    position: fixed;
    left: 0;
    padding: 25px 40px 25px 50px;
    top: 0;
    width: 100%;
    z-index: 200;
    font-size: rem(17px);
    @include for-tablet-layout {
      font-size: rem(22px);
      padding: 32px 40px 32px 50px;
    }
    @include for-mobile-layout{
      padding: 25px 30px 25px 40px;
      font-size: rem(15px);
    }

    h3 {
      @include for-mobile-layout{
        font-size: rem(20px);
      }
      font-weight: 700;
    }
    .hamburger,
    .cancel {
      cursor: pointer;
      display: none;
      font-size: rem(30px);
      @include for-tablet-layout {
        display: block;
      }
      @include for-mobile-layout{
        font-size: rem(22px);
      }
    }
    ul {
      transition: all 0.9s;
      @include for-tablet-layout {
        display: none;
      }
      li {
        transition: all 0.2s;
        &:hover {
          color: $clr5;
        }
        font-weight: 600;
      }
      display: flex;
      gap: rem(20px);
    }
  }
}
