.about-section {
  padding: rem(80px) 0px;
  .about-container {
    margin: 0 auto;
    max-width: rem(950px);
    .about-content {
      @include for-tablet-layout {
        flex-direction: column;
        align-items: center;
        gap: rem(50px);
      }
      gap: rem(80px);
      display: flex;
      .about-img {
        position: relative;
        width: 47%;
        @include for-tablet-layout {
          width: 55%;
        }
        @include for-mobile-layout {
          width: 80%;
        }
        .laptop-img {
          @include for-tablet-layout {
            height: rem(320px);
          }
          @include for-mobile-layout {
            height: rem(200px);
          }
          border-radius: 18px;
          height: 100%;
        }
        .work-emoji {
          bottom: 35px;
          position: absolute;
          right: 15px;
          width: 3.9rem;
          z-index: 10;
          @include for-tablet-layout {
            width: 3.2rem;
            right: 5px;
            bottom: 26px;
          }
          @include for-mobile-layout {
            display: none;
          }
        }
        span {
          background-color: #fff;
          border-radius: 50%;
          bottom: -33px;
          height: 12rem;
          position: absolute;
          right: -50px;
          width: 12rem;
          @include for-tablet-layout {
            width: 10rem;
            height: 10rem;
          }
          @include for-mobile-layout {
            display: none;
          }
          img {
            animation: rotate 9s linear infinite;
            width: 12rem;
            @include for-tablet-layout {
              width: 10rem;
            }
            height: auto;
          }
        }
      }
      .about-info {
        @include for-tablet-layout {
          text-align: center;
          width: 66%;
        }
        @include for-mobile-layout {
          width: 84%;
        }
        width: 55%;
        p {
          color: $clr4;
          margin: 0;
        }
      }
    }
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
