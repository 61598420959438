.flex {
  display: flex;

  > *.full {
    flex: 1;
  }

  &.column {
    flex-direction: column;
  }

  &.justify-center {
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.blue-h4 {
  color: #147efb;
  font-size: rem(18px);
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  @include for-tablet-layout {
    text-align: center;
  }
}

.grey-h3 {
  font-size: 1.5625rem;
  color: #2d2e32;
  line-height: 1.5;
  margin-bottom: 0.9375rem;
  @include for-tablet-layout {
    text-align: center;
  }
}
