.projects-section {
  background-color: #f9f9f9;
  padding: 8rem 0;
  @include for-mobile-layout {
    padding: 5rem 0;
  }
  .projects-container {
    margin: 0 auto;
    max-width: rem(950px);
    .projects-content {
      .grey-h3 {
        margin-bottom: rem(50px);
        @include for-mobile-layout {
          max-width: 320px;
          margin: auto;
          margin-bottom: rem(50px);
        }
      }
      .projects-grid {
        @include for-tablet-layout {
          place-items: center;
        }
        display: grid;
        gap: 3rem;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        height: auto;
        width: 100%;
      }
    }
  }
}
