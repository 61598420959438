// Colors
$clr1: #2d2e32;
$clr2: #ffffff;
$clr3: #f9f9f9;
$clr4: #82848f;
$clr5: #1488fb;

// Breakpoints
$mobile-breakpoint: 460px;
$narrow-breakpoint: 948px;
$tablet-breakpoint: 768px;
$normal-breakpoint: 1024px;
$padding: 30px;
