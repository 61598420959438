.side-links {
  @include for-wide-layout{
    display: none;
  }
  transition: all 500ms ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: -32%;
  @include for-wide-layout {
    bottom: -36%;
  }
  left: rem(55px);
  width: 30px;
  height: rem(300px);
  background-color: transparent;
  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: rem(26px);
    margin-bottom: rem(25px);
    li {
      transition: all 0.3s;
      cursor: pointer;
      i {
        transition: all 0.3s;
        opacity: 0.75;
        display: flex;
        &::before {
          color: $clr1;
        }
        &:hover {
          opacity: 1;
        }
        font-size: rem(25px);
      }
    }
  }

  .side-line {
    opacity: 0.75;
    height: 100px;
    width: 2px;
    background-color: $clr1;
  }
}

.links-up {
  bottom: -2%;
}
