.side-menu-container {
  left: 0px;
  position: fixed;
  top: 100px;
  @include for-mobile-layout {
    top: 80px;
  }
  width: 100%;
  .side-menu {
    transition: all 0.5s;
    background-color: $clr1;
    height: rem(0px);
    display: flex;
    opacity: 0.95;
    align-items: center;
    justify-content: center;
    z-index: 2;
    width: 100%;
    text-align: center;
    ul {
      display: flex;
      display: none;
      opacity: 0;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      li {
        color: $clr2;
        padding: 10px;
        letter-spacing: 1.8px;
      }
    }
  }
}

.add-height {
  height: rem(245px) !important;
  @include for-mobile-layout {
    height: rem(208px) !important;
  }
}

.show-list {
  opacity: 1 !important;
}
