html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  scrollbar-color: #8e8e8e #fff;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 20px;
}

::-webkit-scrollbar-track:active,
::-webkit-scrollbar-track:hover {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #8e8e8e;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #878787;
}

::-webkit-scrollbar-thumb:active {
  background-color: #8e8e8e;
}

body {
  font-family: 'Poppins';
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
button {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
}
