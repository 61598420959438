.project-preview {
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-right: 35px;
  display: flex;
  @include for-tablet-layout {
    flex-direction: column;
    padding-right: 20px;
    width: fit-content;
    height: rem(750px);
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: rem(50px);
  }
  @include for-mobile-layout {
    gap: rem(40px);
  }
  gap: rem(80px);
  height: rem(400px);
  background-color: $clr2;
  border-radius: 18px;
  .pro-img {
    @include for-mobile-layout {
      width: rem(300px);
    }
    background-color: hsla(0, 0%, 46%, 0.2);
    border-radius: 18px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: auto;
    overflow: hidden;
    img {
      transition: transform 10s ease-in-out 0s;
    }
    width: rem(530px);
  }
  .preview-h3 {
    font-size: rem(17px);
    margin: 0 !important;
  }
  .pro-text {
    @include for-tablet-layout {
      width: rem(455px);
    }
    @include for-mobile-layout {
      width: rem(300px);
    }
    h3 {
      @include for-tablet-layout {
        font-size: rem(20px);
      }
    }
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: rem(290px);
    p {
      margin-bottom: rem(10px);
      color: #767676;
      font-weight: 500;
      font-size: rem(17px);
      @include for-tablet-layout {
        font-size: rem(20px);
      }
      @include for-mobile-layout {
        font-size: rem(16px);
      }
    }
    .stack {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: rem(15px);
      margin-top: rem(10px);
      p {
        font-size: 1rem;
        margin: 0;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        color: #000;
        font-family: Mulish, sans-serif;
        font-weight: 800;
        padding: 0.7rem 1rem;
        @include for-mobile-layout {
          font-size: rem(14px);
        }
      }
    }
    .links {
      @include for-tablet-layout {
        justify-content: center;
      }
      @include for-mobile-layout {
        justify-content: space-evenly;
      }
      display: flex;
      justify-content: space-evenly;
      gap: rem(25px);
      margin-top: rem(30px);
      a {
        transition: all 0.2s;
        &:hover {
          color: $clr5;
        }
        cursor: pointer;
        font-weight: 500;
        align-items: center;
        display: flex;
        font-size: rem(17px);
        gap: 5px;
        i {
          font-size: rem(21px);
        }
      }
    }
  }
}

.reverse {
  flex-direction: row-reverse;
  @include for-tablet-layout {
    flex-direction: column;
  }
  @include for-mobile-layout {
    padding-left: 20px;
  }
  padding-right: 20px;
  padding-left: 35px;
}

.appsus-height {
  height: 25rem;
  @include for-tablet-layout {
    height: 46.875rem;
  }
  @include for-mobile-layout {
    height: 39rem !important;
  }
}
