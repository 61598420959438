#contact {
  background-color: #fff;
  padding: 5.7rem 0;
  @include for-mobile-layout {
    padding: 5rem 0 4rem 0;
  }
  .contact-container {
    margin: 0 auto;
    max-width: rem(950px);
    .contact-content {
      display: flex;
      flex-direction: column;
      .contact-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include for-tablet-layout {
          justify-content: space-evenly;
        }
        @include for-mobile-layout {
          flex-direction: column;
          gap: rem(50px);
        }
        margin-top: 3rem;
        .contact-icon-box {
          @include for-tablet-layout {
            flex-direction: column;
          }
          gap: 1rem;
          span {
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            padding: 1.3rem;
            i {
              color: #147efb;
              font-size: 2rem;
            }
          }
          .contact-info {
            @include for-tablet-layout {
              align-items: center;
            }
            display: flex;
            flex-direction: column;
            p {
              margin: 0;
            }
            h3 {
              color: $clr1;
            }
            gap: rem(5px);
          }
          .phone {
            padding: 1.3rem 1.5rem;
          }
        }
      }
    }
  }
}
