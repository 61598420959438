.hero-section {
  height: 100vh;
  @include for-tablet-layout {
    padding: 130px 0px 60px 0px;
    height: auto;
  }
  @include for-mobile-layout {
    padding: 85px 0px 20px 0px;
  }
  background-color: $clr3;
  .hero-container {
    max-width: rem(950px);
    margin: 0 auto;
    h1 {
      font-size: rem(55px);
      line-height: 1.2;
      color: $clr1;
    }
    .content {
      gap: rem(68px);
      @include for-tablet-layout {
        gap: 0px;
      }
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: rem(970px);
      .hero-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        @include for-tablet-layout {
          flex-direction: column-reverse;
          text-align: center;
          gap: rem(40px);
        }
        .hero-img {
          @include for-mobile-layout {
            height: 18rem;
            width: 18rem;
          }
          background-image: url('../../img/portfolio-img.jpg');
          animation: morph 8s ease-in-out infinite;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          border: 3px solid #2d2e32;
          border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
          height: 22rem;
          position: relative;
          transition: all 1s ease-in-out;
          width: 23rem;
        }
      }
      .hero-info {
        position: relative;
        width: rem(500px);
        @include for-mobile-layout{
          width: rem(335px);
        }
        h1 {
          @include for-mobile-layout {
            font-size: rem(39px);
          }
        }
        p {
          @include for-tablet-layout {
            color: $clr1;
          }
          font-family: Mulish, sans-serif;
          font-size: 1.1rem;
          font-weight: 500;
          line-height: 1.6;
          color: $clr4;
        }
        .socials {
          display: flex;
          gap: rem(12px);
          margin: 1.64rem 0;
          a {
            color: #2d2e32;
            font-size: rem(30px);
          }
          @include for-tablet-layout {
            justify-content: center;
            gap: rem(15px);
          }
        }
        img {
          position: absolute;
          width: rem(55px);
          top: rem(70px);
          right: rem(130px);
          @include for-tablet-layout {
            right: rem(30px);
          }
          @include for-mobile-layout{
            display: none;
          }
        }
      }
    }
  }
}

.skills {
  display: flex;
  align-items: center;
  align-self: baseline;
  @include for-tablet-layout {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
  }
  p {
    border-right: 2px solid rgba(45, 46, 50, 0.5);
    color: #2d2e32;
    font-family: Mulish, sans-serif;
    font-weight: 700;
    margin-right: 4rem;
    padding-right: 1.2rem;
    font-size: rem(18px);
    height: rem(21px);
    @include for-tablet-layout {
      border-bottom: 2px solid rgba(45, 46, 50, 0.5);
      border-right: none;
      margin-bottom: 2.5rem;
      margin-right: 0;
      padding-bottom: rem(30px);
      padding-right: 0;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: rem(20px);
    @include for-mobile-layout{
      justify-content: center;
    }
    li {
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
      display: flex;
      height: rem(70px);
      justify-content: center;
      width: rem(70px);
      @include for-mobile-layout{
        height: rem(64px);
        width: rem(64px);
      }
      img {
        height: auto;
        width: rem(35px);
        @include for-mobile-layout{
          width: rem(32px);
        }
      }
    }
  }
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}
